exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-deliveries-js": () => import("./../../../src/pages/deliveries.js" /* webpackChunkName: "component---src-pages-deliveries-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-online-shop-js": () => import("./../../../src/pages/online-shop.js" /* webpackChunkName: "component---src-pages-online-shop-js" */),
  "component---src-pages-orders-js": () => import("./../../../src/pages/orders.js" /* webpackChunkName: "component---src-pages-orders-js" */),
  "component---src-pages-reports-js": () => import("./../../../src/pages/reports.js" /* webpackChunkName: "component---src-pages-reports-js" */),
  "component---src-pages-requests-js": () => import("./../../../src/pages/requests.js" /* webpackChunkName: "component---src-pages-requests-js" */)
}

